exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-templates-blog-post-tsx": () => import("./../../../src/pages/blog/templates/blog-post.tsx" /* webpackChunkName: "component---src-pages-blog-templates-blog-post-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customers-index-tsx": () => import("./../../../src/pages/customers/index.tsx" /* webpackChunkName: "component---src-pages-customers-index-tsx" */),
  "component---src-pages-customers-nalupay-tsx": () => import("./../../../src/pages/customers/nalupay.tsx" /* webpackChunkName: "component---src-pages-customers-nalupay-tsx" */),
  "component---src-pages-customers-novo-tsx": () => import("./../../../src/pages/customers/novo.tsx" /* webpackChunkName: "component---src-pages-customers-novo-tsx" */),
  "component---src-pages-customers-tandym-tsx": () => import("./../../../src/pages/customers/tandym.tsx" /* webpackChunkName: "component---src-pages-customers-tandym-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-start-tsx": () => import("./../../../src/pages/start.tsx" /* webpackChunkName: "component---src-pages-start-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-use-case-buy-now-pay-later-tsx": () => import("./../../../src/pages/use-case/buy-now-pay-later.tsx" /* webpackChunkName: "component---src-pages-use-case-buy-now-pay-later-tsx" */),
  "component---src-pages-use-case-installment-loans-tsx": () => import("./../../../src/pages/use-case/installment-loans.tsx" /* webpackChunkName: "component---src-pages-use-case-installment-loans-tsx" */),
  "component---src-pages-use-case-next-gen-constructs-tsx": () => import("./../../../src/pages/use-case/next-gen-constructs.tsx" /* webpackChunkName: "component---src-pages-use-case-next-gen-constructs-tsx" */),
  "component---src-pages-use-case-payment-cards-tsx": () => import("./../../../src/pages/use-case/payment-cards.tsx" /* webpackChunkName: "component---src-pages-use-case-payment-cards-tsx" */)
}

